import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PublicWorksROW = () => (
  <Layout>
    <SEO title="Public Works Right-of-Way Services" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">
          Public Works Right-of-Way Services
        </h1>
        <p>
          Gray Hawk Land Solutions helps to manage or acquire public
          right-of-way. This includes areas or land owned or controlled by the
          city for the purposes of constructing, operating, and maintaining
          public facilities. The right-of-way assistance we provide encompasses
          streets, alleys, sidewalks, bike paths, and other pathways for more
          accessible transportation, utilities, and other public infrastructure.
          Gray Hawk Land Solutions right-of-way services manages most activities
          associated with public development and use of a city's right-of-way.
        </p>
        <p>
          Gray Hawk Land Solutions works with a city’s right-of-way office who
          is responsible for determining the required improvements and reviewing
          plans for regulatory compliance in the areas of transportation,
          survey, and right-of-way management. Gray Hawk Land Solutions also
          works to acquire inspection and permits for construction of private
          and public development projects in conjunction with the use of a
          city’s right-of-way.
        </p>
      </div>
    </div>
  </Layout>
)

export default PublicWorksROW
